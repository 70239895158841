<template>
  <CDataTable
    :items="computedItems"
    :fields="fields"
    hover
    :items-per-page-select="{label: 'Einträge pro Seite'}"
    :items-per-page="25"
    column-filter
    sorter
    pagination
  >
    <template #no-items-view><NoData/></template>

    <template #actionbar="{item}">
      <td nowrap="" width="1%">
        <CButton
          color="primary"
          variant="outline"
          square
          size="sm"
          v-c-tooltip="{content: 'Editieren', placement:'left'}"
          v-on:click="editEntry(item.projektid)"
        >
          <CIcon name="cil-highlighter"/>
        </CButton>
        <CButton
          color="success"
          variant="outline"
          size="sm"
          class="ml-2"
          v-c-tooltip="{content: 'Inaktiv setzen', placement:'left'}"
          v-on:click="toggleAktiv(item.projektid)"
          v-if="item.aktiv"
        >
          <CIcon name="cil-lock-unlocked"/>
        </CButton>
        <CButton
          color="danger"
          variant="outline"
          size="sm"
          class="ml-2"
          v-c-tooltip="{content: 'Aktiv setzen', placement:'left'}"
          v-on:click="toggleAktiv(item.projektid)"
          v-if="!item.aktiv"
        >
          <CIcon name="cil-lock-locked"/>
        </CButton>
        <CButton
          color="danger"
          variant="outline"
          size="sm"
          class="ml-2"
          v-c-tooltip="{content: 'Löschen', placement:'left'}"
          v-on:click="deleteEntry(item.projektid)"
          v-if="!item.aktiv"
        >
          <CIcon name="cil-trash"/>
        </CButton>
      </td>
    </template>
  </CDataTable>
</template>

<script>
import Vue from 'vue'
import NoData from '@/components/NoData'

export default {
  name: 'ProjektTable',
  props: {
    projekte: Array,
    value: String
  },
  components: {
    NoData
  },
  data () {
    return {
      fields: [
        {
          key: 'name',
          label: 'Name'
        },
        {
          key: 'strasse',
          label: 'Strasse'
        },
        {
          key: 'ort',
          label: 'Ort'
        },
        {
          key: 'actionbar',
          label: '',
          _style: 'width:1%',
          sorter: false,
          filter: false
        }
      ]
    }
  },
  mounted () {
  },
  computed: {
    computedItems () {
      if (this.projekte) {
        if (this.projekte.length > 0) {
          return this.projekte.map(prj => {
            const result = {
              projektid: prj.projektid,
              aktiv: prj.aktiv,
              name: prj.name,
              strasse: prj.strasse + ' ' + prj.hausnr,
              ort: prj.plz + ' ' + prj.ort
            }

            return result
          })
        } else {
          return []
        }
      } else {
        return []
      }
    }
  },
  methods: {
    editEntry (projektid) {
      this.$router.push({ path: `/projekte/editieren/${projektid}` })
    },
    toggleAktiv: function (projektid) {
      const self = this
      const options = {
        okText: 'Ja',
        cancelText: 'Nein'
      }
      self.$dialog
        .confirm('Den Status des Projekts wirklich ändern?', options)
        .then((dialog) => {
          Vue.axios.put('/projekte/toggle/aktiv/' + projektid)
            .then(function (response) {
              self.$snotify.success('Der Status des Projekts wurde geändert.', {
                position: 'rightTop',
                timeout: 4000
              })
              self.$emit('input', projektid)
            })
        })
        .catch(() => {
          // Do Nothing
        })
    },
    deleteEntry: function (projektid) {
      const self = this
      const options = {
        okText: 'Ja',
        cancelText: 'Nein'
      }
      this.$dialog
        .confirm('Das Projekt wirklich löschen?', options)
        .then((dialog) => {
          Vue.axios.delete('/projekte/delete/' + projektid)
            .then((response) => {
              self.$snotify.error('Das Projekt wurde gelöscht.', {
                position: 'rightTop',
                timeout: 4000
              })
              self.$emit('input', projektid)
            })
        })
        .catch(() => {
          // Do Nothing
        })
    }
  }
}
</script>
