<template>
  <CRow>
    <CCol sm="12">
      <CCard>

        <CCardHeader>
          <CIcon name="cil-lan"/>
          <h5>Übersicht über alle Projekte eines Kunden</h5>
          <div class="card-header-actions col-12 col-md-6 text-right">
            <CRow>
              <CCol sm="12">
                <KundenAuswahl
                  v-bind:validate="false"
                  formLabelCLass="d-none"
                  v-model="kunde"
                />
              </CCol>
              <CCol>
                <CButton color="success" size="sm" v-on:click="newEntry" v-if="kunde"><CIcon name="cil-library-add"/> Neues Projekt</CButton>
              </CCol>
            </CRow>
          </div>
        </CCardHeader>

        <CCardBody>
          <CRow>
            <CCol sm="12">

              <CTabs>
                <CTab add-tab-classes="mt-1">
                  <template slot="title">
                    <CIcon name="cil-thumb-up"/>
                    <span>Aktiv</span>
                  </template>
                  <Table :projekte="aktiv" v-model="reload"/>
                </CTab>
                <CTab add-tab-classes="mt-1">
                  <template slot="title">
                    <CIcon name="cil-thumb-down"/>
                    <span>Inaktiv</span>
                  </template>
                  <Table :projekte="inaktiv" v-model="reload"/>
                </CTab>
              </CTabs>
            </CCol>
          </CRow>
        </CCardBody>

      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import Vue from 'vue'
import KundenAuswahl from '@/components/KundenAuswahl'
import Table from '@/views/Projekte/Table'

export default {
  name: 'KundenIndex',
  components: {
    KundenAuswahl,
    Table
  },
  props: {
    uuid: [Number, String]
  },
  data () {
    return {
      kunde: null,
      projekte: null,
      reload: ''
    }
  },
  computed: {
    aktiv () {
      if (this.projekte) {
        return this._.filter(this.projekte, function (prj) {
          return prj.aktiv
        })
      } else {
        return null
      }
    },
    inaktiv () {
      if (this.projekte) {
        return this._.filter(this.projekte, function (prj) {
          return !prj.aktiv
        })
      } else {
        return null
      }
    }
  },
  async mounted () {
    if (this.uuid) {
      await this.loadKunde(this.uuid)
      this.loadProjekte()
    }
  },
  watch: {
    reload: function () {
      if (this.reload) {
        this.loadProjekte()
        this.reload = ''
      }
    },
    kunde: function () {
      this.loadProjekte()
    }
  },
  methods: {
    newEntry () {
      this.$router.push({ path: '/projekte/neu/' + (this.kunde.kundenid ? this.kunde.kundenid : this.kunde.kundenid ? this.kunde.kundenid : this.uuid) }) // wichtig kundenid nicht kundenid
    },
    async loadKunde (id) {
      await Vue.axios.get('/kunden/get/' + id)
        .then((response) => {
          this.kunde = response.data
        })
    },
    loadProjekte () {
      if (this.kunde) {
        Vue.axios.get('/projekte/get/' + (this.kunde.kundenid ? this.kunde.kundenid : this.kunde.kundenid ? this.kunde.kundenid : this.uuid)) // wichtig kundenid nicht kundenid
          .then((response) => {
            this.projekte = response.data
          })
      }
    }
  }
}
</script>
