<template>
  <div class="my-5">
    <h2>
      {{text}}
      <CIcon
        width="30"
        name="cil-ban"
        class="text-danger mb-2"
      />
    </h2>
  </div>
</template>

<script>

export default {
  name: 'NoData',
  props: {
    text: {
      default: 'Keine Daten für die Anzeige.',
      type: String
    }
  }
}
</script>
